<template>
     <!--Content start-->
    <main id="content">
      <div class="container">
        <div class="row">
             <!--breadcrumb-->
          <div class="col-12">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb u-breadcrumb pt-3 px-0 mb-0 bg-transparent small " v-for="(news, index) in feedNews"
              v-bind:key="index">
                <li class="breadcrumb-item "><router-link to="/">الصفحة الرئيسية</router-link></li>
                <li class="breadcrumb-item "><a>{{ news.name }}</a></li>
              </ol>
            </nav>
          </div>
          <!--end breadcrumb-->
          <!--content-->
          <div class="col-md-8">
            <div class="post-title mb-4" v-for="(news, index) in feedNews"
              v-bind:key="index">
              <!-- title -->
              <h2 class="entry-title display-4 display-2-lg mt-2 ">{{ news.post_title }}</h2>
              <!-- atribute -->
              <div class="post-atribute mb-3">
                <div class="my-3 text-muted small">
                  <!--author-->
                  <span class="d-none d-sm-inline ">
                    بواسطة <a class="fw-bold" href="">تاق برس</a>
                  </span>
                  <!--date-->
                  <time class="ms-0 ms-sm-2 ms-md-3" datetime="2019-10-22">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-calendar-check me-1" viewBox="0 0 16 16">
                      <path d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                      <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
                    </svg> {{ news.post_date.substring(0,10) }}
                  </time>
                </div>
          
              </div>
              
            </div>
            <!-- post content -->
            <div class="post-content"  v-for="(news, index) in feedNews"
              v-bind:key="index">
              <!-- images -->
              <figure class="image-wrapper ratio_single">
                <img class="img-fluid " v-lazy="news.open_graph_image" alt="Image description">
                <figcaption></figcaption>
              </figure>
              <p class=" about text-justify" v-html="news.post_content"></p>
              <!-- images -->
             
            </div>

            <!-- footer post -->
            <div class="entry-footer">
              <!-- tags -->
              <div class="tags-area">
                <ul class="tags-links tagcloud ps-0 ">
                  <li><span class="fw-bold me-2">Tags</span></li>
                  <li><router-link to="/">الرئيسية</router-link></li>
                  <li><router-link to="/Policy">سياسية</router-link></li>
                  <li><router-link to="/Health">صحة</router-link></li>
                  <li><router-link to="/Economie">اقتصادية</router-link></li>
                  <li><router-link to="/Article">مقالات</router-link></li>
                  <li><router-link to="/Sport">رياضة</router-link></li>
                </ul>
              </div>
              <div class="gap-0"></div>
            </div>

       

            <!--Related post title-->
            <div class="block-area">
              <div class="block-title-14" style="margin-bottom: 0rem;">
                  <h4 class="h5 bg-secondary">
                      <span class="bg-primary text-white" style="background-color: #444444 !important;">أخبار ذات صلة</span>
                  </h4>
              </div>

               <!-- block content -->
              <div class="row">
                <!--post list-->
                <article class="col-sm-6 col-md-12 col-lg-6" v-for="publish in news" :key="publish.id">
                  <!--thumbnail-->
                    <div class="ratio_360-202 image-wrapper">
                      <a @click="getFeed(publish)">
                        <img class="img-fluid pointer" v-lazy="publish.open_graph_image" alt="Image description">
                      </a>
                    </div>
                    <div class="card-body">
                      <!-- title -->
                      <h2 class="card-title h1-sm h3-lg  pointer">
                        <a @click="getFeed(publish)">{{ publish.post_title.substring(0,70) }}</a>
                      </h2>
                      <!-- author, date and comments -->
                      <div class="card-text mb-2 text-muted small">
                        <span class="d-none d-sm-inline me-1 ">
                          <a class="fw-bold" href="#">تاق برس</a>
                        </span>
                        <time datetime="2019-10-22">{{ publish.post_date.substring(0,10) }}</time>
                      </div>
                      <!--description-->
                    </div>
                </article>
                <div class="gap-05"></div>
              </div>
              <!-- end block content -->
              </div>
     
          </div>
          <!-- end content -->
          <!-- start right column -->
          <aside class="col-md-4 end-sidebar-lg">
            <!--sticky-->
            <div class="sticky">
              <!-- latest post -->
              <aside class="widget">
                <div class="block-title-14" style="margin-bottom: 0rem;">
                  <h4 class="h5 bg-secondary">
                      <span class="bg-primary text-white" style="background-color: #444444 !important;">احدث الموضوعات</span>
                  </h4>
              </div>
                <!--post big start-->
                <div class="big-post">
                  <article class="card card-full hover-a mb-4" v-for="publish in lastnews.slice(0,1)" :key="publish.id">
                    <!--thumbnail-->
                    <div class="ratio_360-202 image-wrapper">
                      <a class="card-link pointer"  @click="getFeed(publish)">
                        <img class="img-fluid" v-lazy="publish.open_graph_image" alt="Image description">
                      </a>
                    </div>
                    <div class="card-body">
                      <!--title-->
                      <h2 class="card-title h1-sm h3-md ">
                        <a class="card-link pointer"  @click="getFeed(publish)">{{ publish.post_title.substring(0,70) }}</a>
                      </h2>
                      <!-- author & date -->
                      <div class="card-text mb-2 text-muted small">
                        <span class="d-none d-sm-inline me-1">
                          <a class="fw-bold" href="#">تاق برس</a>
                        </span>
                        <time datetime="2019-10-22">{{ publish.post_date.substring(0,10) }}</time>
                      </div>
                      <!--description-->
                    </div>
                  </article>
                </div>
                <!--end post big-->
                <!--post small start-->
                <div class="small-post">
                  <!--post list-->
                  <article class="card card-full hover-a mb-4" v-for="publish in lastnews.slice(1,5)" :key="publish.id">
                    <div class="row">
                      <!--thumbnail-->
                      <div class="col-3 col-md-4 pe-2 pe-md-0">
                        <div class="ratio_110-77 image-wrapper">
                          <a class="card-link pointer"  @click="getFeed(publish)">
                            <img class="img-fluid" v-lazy="publish.open_graph_image" alt="Image description">
                          </a>
                        </div>
                      </div>
                      <!-- title & date -->
                      <div class="col-9 col-md-8">
                        <div class="card-body pt-0">
                          <h3 class="card-title h6 h5-sm h6-md ">
                            <a class="card-link pointer"  @click="getFeed(publish)">{{ publish.post_title.substring(0,50) }}</a>
                          </h3>
                          <div class="card-text small text-muted">
                            <time datetime="2019-10-16">{{ publish.post_date.substring(0,10) }}</time>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                  <!--post list-->
                
                </div>
                <!--end post small-->
                <div class="gap-0"></div>
              </aside>
              <!-- end latest post -->
            </div>
          </aside>
        </div>
      </div>
    </main>
  
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { useRouter, useRoute } from "vue-router";
import { ref } from '@vue/reactivity';
import { useCookie } from 'vue-cookie-next'
import { useMeta } from 'vue-meta'
export default {
  name: 'ReadShow',
    props: {
       id: String,
    },
    async setup(props) {
        useMeta({ title: 'اخر الاخبار' })
         const router = useRouter();
         const route = useRoute();
         const cookie = useCookie();
         const feedNews = ref([]);
         const lastnews = ref([]);
         const news = ref([]);

        try {
          await HTTP.get("readPost.php?readPost&id=" + props.id).then((res) => {
            feedNews.value = res.data.readPost;
          });
        } catch (err) {
            console.log(err);
        }
        try {
          await HTTP.get("getPost.php?getPost&LIMIT=5&OFFSET=0").then((res) => {
            lastnews.value = res.data.getPost; 
          });
        } catch (err) {
          console.log(err);
        }

        try {
           await HTTP.get(`getPostbyCateogry.php?getPostbyCateogry&term_id=${cookie.getCookie("term_id")}&LIMIT=4&OFFSET=0`).then((res) => {
              news.value = res.data.getPostbyCateogry;
           })
        } catch (err) {
           console.log(err);
        }

        const getFeed = (publish) => {
          cookie.setCookie("term_id", publish.term_id);
          router.push({ name: "ReadArticle", params: { id: publish.id } });
        };
    return {
      feedNews,
      lastnews,
      getFeed,
      news
    }
  },


}
</script>

<style scoped>
.bg-secondary {
  background-color: #F2F2F2 !important;
  clip-path: polygon(2% 0%, 100% 0%, 99% 100%, 0% 100%);
}
.pointer {
  cursor: pointer;
}
.about p {
  margin: 0;
  text-indent: 2rem;
  text-align: justify;
}
 .one_rem {
   height: 1rem;
   width: 1rem;
 }
</style>